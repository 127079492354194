import React from "react";

import styles from "../styles";

const data = [
  {
    subtitle: "Terms",
    points: [
      "By accessing this School, you are agreeing to be bound by these Terms of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in thes courses are protected by applicable copyright and trademark law.",
    ],
  },
  {
    subtitle: "Use License",
    points: [
      "Permission is granted to temporarily download one copy of any downloadable materials on the School’s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not: modify or copy the materials; use the materials for any commercial purpose, or for any public display (commercial or non-commercial); attempt to decompile or reverse engineer any software contained on the School’s web site; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or 'mirror' the materials on any other server.",
      "This license shall automatically terminate if you violate any of these restrictions and may be terminated by Company at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.",
    ],
  },
  {
    subtitle: "JS Mastery Pro Courses",
    points: [
      "By purchasing any courses available on the JS Mastery Pro platform, you’re eligible for indefinite access without further payment.",
      "In the future, JS Mastery Pro may release new courses. In that case, you would need to purchase access to those courses separately.",
    ],
  },
  {
    subtitle: "JS Mastery Pro Masterclass Experience",
    points: [
      "JS Mastery Pro Masterclass Experience is a three-month or six-month program taught by Adrian and other JS Mastery Pro instructors. You can purchase the program by paying a one-time fee or splitting the payment across three to six months of equal amounts.",
    ],
  },
  {
    subtitle: "Refund Policy for Courses",
    points: [
      "We offer a no questions asked refund policy. To request a refund, send us an email at contact@jsmastery.pro.",
    ],
  },
  {
    subtitle: "Refund Policy JS Mastery Pro Masterclass Experience",
    points: [
      "You have 14 days to request a refund for the program. To request a refund, send us an email at contact@jsmastery.pro.",
    ],
  },
];

const TermsConditions = () => {
  return (
    <div className={`${styles.flexCenter} ${styles.paddings}`}>
      <div
        className={`${styles.innerWidth} ${styles.navPadding} flex flex-col`}
      >
        <h1 className={`${styles.heading2} text-left text-site-black`}>
          Terms and Conditions
        </h1>

        <div className="sm:mt-10 mt-5">
          {data.map((item, index) => (
            <div className="w-full flex flex-col my-8" key={`tc-${index}`}>
              <h3
                className={`${styles.heading4} text-site-black text-left mb-2`}
              >
                {item.subtitle}
              </h3>

              {item.points.map((content, inx) => (
                <p
                  className={`${styles.subtitle2} text-left my-4 text-site-gray`}
                  key={`tc-content-${inx}`}
                >
                  {content}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
