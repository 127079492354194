import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { close, logo, menu } from "../assets";
import styles from "../styles";

const links = [
  {
    id: "link-1",
    name: "Features",
  },
  {
    id: "link-2",
    name: "Pricing",
  },
  {
    id: "link-3",
    name: "Clients",
  },
];

const Navbar = () => {
  const [active, setActive] = useState("");
  const [navbarShadow, setNavbarShadow] = useState(false);
  const [toggleSiderbar, setToggleSidebar] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // add shadow to navbar when scroll
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) {
        setNavbarShadow(true);
      } else {
        setNavbarShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // CHECK: Is that a good logic to handle hash and routing?
  useEffect(() => {
    if (location.pathname !== "/") setActive("");

    if (location.pathname === "/" && location.hash !== "")
      setActive(location.hash.replace(/#/g, ""));

    console.log(location);
  }, [location]);

  return (
    <header
      className={`${styles.flexCenter} fixed z-10 w-full bg-white ${
        navbarShadow ? "shadow-sm" : "shadow-none"
      } ${styles.xPaddings} py-4`}
    >
      <nav
        className={`${styles.innerWidth} flex flex-row justify-between items-center`}
      >
        <img
          src={logo}
          alt="real_sign"
          className="w-40 object-contain cursor-pointer"
          onClick={() => {
            navigate("/");
            window.scrollTo(0, 0);
            setActive("");
          }}
        />

        <ul className="list-none flex-1 lg:flex hidden flex-row justify-end items-center">
          {links.map((link, index) => (
            <li
              key={link.id}
              className={`relative mr-10 ${
                styles.subtitleRegular
              } font-medium ${
                active === link.name ? "text-site-green" : "text-site-gray"
              } hover:text-site-black cursor-pointer`}
              onClick={() => setActive(link.name)}
            >
              <a href={`/#${link.name}`}>{link.name}</a>

              <div
                className={`${
                  active === link.name ? "block" : "hidden"
                } absolute -right-2 top-0 w-2 h-2 rounded-full bg-site-green`}
              />
            </li>
          ))}

          <button
            onClick={() => window.open("https://client.realsign.app")}
            className="outline-none min-h-[40px] px-6 mr-2 rounded-3xl font-poppins font-semibold text-site-black"
          >
            Sign In
          </button>

          <button
            onClick={() => window.open("https://client.realsign.app")}
            className="outline-none min-h-[40px] px-6 rounded-3xl bg-site-green font-poppins font-semibold text-white"
          >
            Sign Up
          </button>
        </ul>

        <img
          src={!toggleSiderbar ? menu : close}
          alt="menu"
          className="w-10 h-10 object-contain cursor-pointer lg:hidden block"
          onClick={() => setToggleSidebar(!toggleSiderbar)}
        />

        <ul
          className={`lg:hidden flex flex-col items-end justify-end absolute top-[73px] bg-site-light pt-4 min-w-[150px] transition-all duration-700 rounded-[10px] sm:right-16 right:px-8 right-6 ${
            toggleSiderbar ? "top-[80px] opacity-100" : "top-[25vh] opacity-0"
          }`}
        >
          {links.map((link) => (
            <li
              key={link.id}
              className={`relative mr-6 ml-4 mb-4 font-poppins font-semibold text-[16px] ${
                active === link.name ? "text-site-green" : "text-site-black"
              } hover:text-site-black cursor-pointer`}
              onClick={() => {
                setActive(link.name);
                setToggleSidebar(false);
              }}
            >
              <a href={`#${link.name}`}>{link.name}</a>

              <div
                className={`${
                  active === link.name ? "block" : "hidden"
                } absolute -right-2 -top-[2px] w-2 h-2 rounded-full bg-site-green`}
              />
            </li>
          ))}

          <button
            onClick={() => window.open("https://client.realsign.app")}
            className="outline-none mr-6 mb-4 rounded-md font-poppins font-semibold text-site-black text-[16px]"
          >
            Sign In
          </button>

          <div
            onClick={() => window.open("https://client.realsign.app")}
            className={`${styles.flexCenter} w-full bg-site-green rounded-b-[10px]`}
          >
            <button
              className={`font-poppins font-semibold text-[16px] text-white text-center outline-none min-h-[50px]`}
            >
              Sign Up
            </button>
          </div>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
