import React from "react";
import { motion } from "framer-motion";

import styles from "../styles";
import { civilis, jsm, pwd, quotes } from "../assets";
import {
  cardVariants,
  headTextVariants,
  zoomInVariants,
} from "../utils/motion";

export const feedback = [
  {
    id: "feedback-1",
    content: "RealSign rocks! It seals the system from the bottom to the top.",
    name: "Unknown",
    title: "Founder & CEO",
    img: "https://via.placeholder.com/150",
  },
  {
    id: "feedback-2",
    content:
      "Now I don't have to worry about some bad actors damaging our department and country.",
    name: "Unknown",
    title: "Secretary",
    img: "https://via.placeholder.com/150",
  },
  {
    id: "feedback-3",
    content: "Now I don't have to remember where I put my USB token.",
    name: "Unknown",
    title: "Chief Technology Officer",
    img: "https://via.placeholder.com/150",
  },
];

const topClients = [civilis, pwd, jsm, civilis, pwd, jsm, civilis];

const SeeAllBtn = () => (
  <div className={`${styles.flexEnd} w-full`}>
    <button
      className={`outline-none bg-transparent border-none font-poppins font-semibold text-[16px] text-site-black`}
    >
      See All &gt;
    </button>
  </div>
);

const FeedbackCard = ({ content, name, title, img }) => (
  <motion.div
    className="flex justify-between flex-col px-10 py-12 rounded-[20px]  max-w-[370px] md:mr-10 sm:mr-5 mr-0 my-5 feedback-card"
    variants={cardVariants}
    exit="exit"
    initial="hidden"
    whileInView="visible"
  >
    <img
      src={quotes}
      alt="double_quotes"
      className="w-[42.6px] h-[27.6px] object-contain quotes-img"
    />
    <p className="font-poppins font-normal text-[18px] leading-[32.4px] text-site-gray my-10">
      {content}
    </p>

    <div className="flex flex-row">
      <img src={img} alt={name} className="w-[48px] h-[48px] rounded-full" />
      <div className="flex flex-col ml-4">
        <h4 className="font-poppins font-semibold text-[20px] leading-[32px] text-site-black">
          {name}
        </h4>
        <p className="font-poppins font-normal text-[16px] leading-[24px] text-site-gray">
          {title}
        </p>
      </div>
    </div>
  </motion.div>
);

const Feedback = () => {
  return (
    <section className={`${styles.flexCenter} ${styles.paddings}`}>
      <div
        className={`${styles.flexCenter} ${styles.innerWidth} flex-col w-full`}
      >
        <span id="Clients" className="hash-span" />

        <motion.div
          className="w-full flex justify-between items-center md:flex-row flex-col sm:mb-16 mb-6"
          variants={headTextVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
        >
          <h2
            className={`${styles.heading2} text-site-black flex-1 md:text-left text-center`}
          >
            What People are <br className="sm:block hidden" /> saying about us
          </h2>
          <div className="flex-1 md:ml-6 ml-0 md:mt-0 mt-6">
            <p
              className={`${styles.subtitleRegular} md:text-left text-center text-site-gray max-w-[450px]`}
            >
              See how RealSign is serving clients and users in various ways.
            </p>
          </div>
        </motion.div>

        <div className="flex w-full flex-col">
          <SeeAllBtn />

          <div className="flex flex-wrap sm:justify-start justify-center w-full">
            {feedback.map((card) => (
              <FeedbackCard key={card.id} {...card} />
            ))}
          </div>
        </div>

        <div className="flex w-full flex-col mt-10">
          <SeeAllBtn />

          <div className="flex flex-wrap justify-evenly w-full">
            {topClients.map((item, index) => (
              <motion.div
                variants={zoomInVariants}
                exit="exit"
                initial="hidden"
                whileInView="visible"
                className={`${styles.flexCenter}`}
              >
                <img
                  key={`topClients-${index}`}
                  src={item}
                  alt="client"
                  className="w-24 object-contain m-4 grayscale hover:grayscale-0 ease-in transition-all"
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
