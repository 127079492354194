import React from "react";
import { motion } from "framer-motion";

import styles from "../styles";
import { applePlayBadge, googlePlayBadge } from "../assets";
import { headTextVariants, zoomInVariants } from "../utils/motion";

const DownloadApp = () => {
  return (
    <section
      className={`${styles.flexCenter} ${styles.paddings} bg-site-light`}
    >
      <div className={`${styles.flexCenter} ${styles.innerWidth} flex-col`}>
        <span id="Download" className="hash-span" />

        <motion.div
          variants={headTextVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
          className={`${styles.flexCenter} flex-col`}
        >
          <div
            className={`py-2 px-4 bg-site-light-green w-fit ${styles.flexCenter} rounded-lg`}
          >
            <p
              className={`${styles.smallText} font-medium text-site-green text-center`}
            >
              Download App
            </p>
          </div>

          <h2 className={`${styles.heading2} text-center text-site-black my-5`}>
            Our mobile apps are <br /> now available
          </h2>

          <p className={`${styles.subtitleRegular} text-site-gray text-center`}>
            RealSign is now available both on App Store and Google PlayStore{" "}
          </p>
        </motion.div>

        <motion.div
          className={`${styles.flexCenter} flex-wrap mt-5`}
          variants={zoomInVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
        >
          <img
            src={googlePlayBadge}
            alt="google-play-badge"
            className="object-contain w-[210px] h-[80px] m-2 cursor-pointer"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=app.realsign"
              )
            }
          />
          <img
            src={applePlayBadge}
            alt="google-play-badge"
            className="object-contain w-[168px] h-[58px] m-2 cursor-pointer"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default DownloadApp;
