import React from "react";
import { motion } from "framer-motion";

import styles from "../styles";
import { cancel, check } from "../assets";
import { cardVariants, headTextVariants } from "../utils/motion";

const features = [
  {
    id: "feature-1",
    title: "Document Modification Security",
    type1: false,
    type2: true,
    type3: true,
  },
  {
    id: "feature-2",
    title: "Basic PIN/Password Security",
    type1: true,
    type2: true,
    type3: true,
  },
  {
    id: "feature-3",
    title: "Biometric Security",
    type1: false,
    type2: false,
    type3: true,
  },
  {
    id: "feature-4",
    title: "OS/Hardware Backed Key Security",
    type1: false,
    type2: true,
    type3: true,
  },
  {
    id: "feature-5",
    title: "Signed and Sandboxed App",
    type1: false,
    type2: false,
    type3: true,
  },
  {
    id: "feature-6",
    title: "Instant User Registration/Sign Up",
    type1: true,
    type2: false,
    type3: true,
  },
  {
    id: "feature-7",
    title: "Optional KYC Verification & Enforcement",
    type1: false,
    type2: true,
    type3: true,
  },
  {
    id: "feature-8",
    title: "PKI Certificate Authority",
    type1: false,
    type2: true,
    type3: true,
  },
  {
    id: "feature-9",
    title: "Inbuilt Secure Timestamping",
    type1: false,
    type2: false,
    type3: true,
  },
  {
    id: "feature-10",
    title: "Audit Logs",
    type1: true,
    type2: false,
    type3: true,
  },
  {
    id: "feature-11",
    title: "OS Independent Web App Integration",
    type1: true,
    type2: false,
    type3: true,
  },
  {
    id: "feature-12",
    title: "Signature Verifiable in Adobe Reader",
    type1: false,
    type2: true,
    type3: true,
  },
  {
    id: "feature-13",
    title: "Inbuilt and Strict(Timestamping) Verification in Web App",
    type1: false,
    type2: false,
    type3: true,
  },
];

const Comparison = () => {
  return (
    <section className={`${styles.paddings} ${styles.flexCenter}`}>
      <div className={`${styles.innerWidth} flex flex-col w-full`}>
        <span id="Features" className="hash-span" />

        <motion.div
          variants={headTextVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
        >
          <h2 className={`${styles.heading2} text-site-black text-center mb-5`}>
            Features & Comparison
          </h2>
          <p
            className={`${styles.subtitleRegular} text-site-gray mb-10 text-center`}
          >
            Choose wisely, Sign easily
          </p>
        </motion.div>

        <motion.div
          className="flex flex-col overflow-x-auto"
          variants={cardVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
        >
          <div className="w-full flex flex-row rounded-t-lg border-b-[1px]">
            <div className="flex-[2] min-w-[300px] flex p-4">
              <h3 className={styles.heading4}>Features</h3>
            </div>
            <div className="flex-[0.3] min-w-[120px] p-4">
              <h3 className={`${styles.subtitleBold} text-center`}>
                Photo Sign
              </h3>
            </div>
            <div className="flex-[0.3] min-w-[120px] p-4">
              <h3 className={`${styles.subtitleBold} text-center`}>USB Sign</h3>
            </div>
            <div className="flex-[0.3] min-w-[120px] p-4 bg-site-green rounded-t-lg sticky right-0">
              <h3 className={`${styles.subtitleBold} text-white text-center`}>
                RealSign
              </h3>
            </div>
          </div>

          {features.map((item, index) => (
            <div key={item.id} className={`w-full flex flex-row`}>
              <div
                className={`flex-[2] min-w-[300px] flex items-center p-4 border-l-[1px] border-b-[1px] ${
                  index === 0 && "rounded-tl-lg"
                } ${index === features.length - 1 && "rounded-bl-lg"}`}
              >
                <p className={`${styles.bodyText} text-site-black`}>
                  {item.title}
                </p>
              </div>
              <div
                className={`flex-[0.3] min-w-[120px] p-4 ${styles.flexCenter} border-b-[1px] border-r-[1px] border-l-[1px]`}
              >
                <img
                  src={item.type1 ? check : cancel}
                  alt="type1"
                  className="w-8 h-8 object-contain"
                />
              </div>
              <div
                className={`flex-[0.3] min-w-[120px] p-4 ${styles.flexCenter} border-b-[1px]`}
              >
                <img
                  src={item.type2 ? check : cancel}
                  alt="type1"
                  className="w-8 h-8 object-contain"
                />
              </div>
              <div
                className={`flex-[0.3] min-w-[120px] p-4 ${
                  styles.flexCenter
                } sticky right-0 bg-site-green border-b-[1px] border-site-light-green shadow-md
                    ${index === features.length - 1 && "rounded-br-lg"}
                `}
              >
                <img
                  src={item.type3 ? check : cancel}
                  alt="type1"
                  className="w-8 h-8 object-contain"
                />
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Comparison;
