import React from "react";

import styles from "../styles";
import {
  Hero,
  Feature,
  Comparison,
  Pricing,
  Feedback,
  DownloadApp,
} from "../components";

const Home = () => {
  return (
    <div className={styles.navPadding}>
      <Hero />
      <Feature />
      <Comparison />
      <Pricing />
      <Feedback />
      <DownloadApp />
    </div>
  );
};

export default Home;
