import React from "react";
import { motion } from "framer-motion";

import styles from "../styles";
import { fast, reliable, secure } from "../assets";
import { cardVariants, headTextVariants } from "../utils/motion";

const features = [
  {
    id: "feature-1",
    title: "Fast",
    description:
      "Signature (incl. Timestamp) is performed and verified within a second",
    imgUrl: fast,
    imgBg: "bg-green-100",
  },
  {
    id: "feature-2",
    title: "Reliable",
    description: "Fully compliant with PKI & PKCS standards",
    imgUrl: reliable,
    imgBg: "bg-blue-100",
  },
  {
    id: "feature-3",
    title: "Secure",
    description:
      "OS/Hardware backed keys, signed and sandboxed App give the ultimate security",
    imgUrl: secure,
    imgBg: "bg-orange-100",
  },
];

const FeatureCard = ({ feature, index }) => (
  <motion.div
    className={`${
      styles.flexCenter
    } flex-col max-w-[350px] px-6 py-16 sm:m-6 mx-0 my-6 rounded-[25px] hover:shadow-lg hover:bg-white ${
      index === 1 ? "bg-white shadow-lg" : ""
    }`}
    variants={cardVariants}
    exit="exit"
    initial="hidden"
    whileInView="visible"
  >
    <div
      className={`${styles.flexCenter} w-[80px] h-[80px] rounded-full ${feature.imgBg} mb-8`}
    >
      <img
        src={feature.imgUrl}
        className="w-[40%] h-[40%] object-contain"
        alt="fast"
      />
    </div>

    <div className="w-full">
      <h4
        className={`${styles.heading4} text-site-black text-center sm:mb-6 mb-4`}
      >
        {feature.title}
      </h4>
      <p className={`${styles.subtitleRegular} text-site-gray text-center`}>
        {feature.description}
      </p>
    </div>
  </motion.div>
);

const Feature = () => {
  return (
    <div className={`${styles.flexCenter} ${styles.paddings} bg-site-light`}>
      <div className={`${styles.flexCenter} ${styles.innerWidth} flex-col`}>
        <motion.div
          variants={headTextVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
        >
          <p
            className={`${styles.subtitleRegular} text-site-green text-center`}
          >
            how is RealSign?
          </p>
          <h3
            className={`${styles.heading2} text-site-black text-center mt-4 sm:mb-10 mb-2`}
          >
            Overview
          </h3>
        </motion.div>

        <div className={`${styles.flexCenter} flex-wrap`}>
          {features.map((feature, index) => (
            <FeatureCard key={feature.id} feature={feature} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feature;
