const styles = {
  heading1:
    "font-poppins font-bold sm:text-[64px] sm:leading-[74px] text-[40px] leading-[50px] tracking-[-0.5%]",
  heading2:
    "font-poppins font-bold sm:text-[48px] sm:leading-[58px] text-[30px] leading-[40px] tracking-[-0.5%]",
  heading3:
    "font-poppins font-bold sm:text-[32px] sm:leading-[42px] text-[28px] leading-[38px] tracking-[-0.25%]",
  heading4:
    "font-poppins font-semibold xs:text-[24px] xs:leading-[34px] text-[20px] leading-[30px] tracking-[0.25%]",

  subtitleBold:
    "font-poppins font-semibold sm:text-[18px] sm:leading-[30px] text-[14px] leading-[24px] tracking-[0.25%]",

  subtitleRegular:
    "font-poppins font-regular sm:text-[18px] sm:leading-[30px] text-[14px] leading-[24px] tracking-[0.25%]",

  bodyText:
    "font-poppins font-regular sm:text-[16px] sm:leading-[26px] text-[12px] leading-[22px] tracking-[0.25%]",

  smallText:
    "font-poppins font-regular sm:text-[12px] sm:leading-[20px] text-[10px] leading-[18px] tracking-[0.25%]",

  innerWidth: "3xl:max-w-[1280px] w-full",

  paddings: "sm:p-16 xs:p-8 px-6 py-12",
  yPaddings: "sm:py-16 xs:py-8 py-12",
  xPaddings: "sm:px-16 xs:px-8 px-6",
  topPadding: "sm:pt-16 xs:pt-8 pt-12",
  navPadding: "pt-[70px]",

  flexCenter: "flex justify-center items-center",
  flexBetween: "flex justify-between items-center",
  flexEnd: "flex justify-end items-start",
  flexStart: "flex justify-start items-start",
};

export default styles;
