import React from "react";
import { motion } from "framer-motion";

import { heroImg } from "../assets";
import { heroImg2 } from "../assets";
import styles from "../styles";
import { slideLeftVariants, slideRightVariants } from "../utils/motion";

const Hero = () => {
  return (
    <div
      className={`${styles.flexCenter} ${styles.paddings} overflow-x-hidden hero hide-scrollbar`}
    >
      <div
        className={`${styles.flexCenter} ${styles.innerWidth} lg:flex-row flex-col w-full`}
      >
        <motion.div
          className={`flex justify-start flex-1 flex-col`}
          variants={slideRightVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
        >
          <h1 className={`${styles.heading1} text-site-black`}>
            It's the <span className="text-site-green">Real</span> <br />{" "}
            Signature
          </h1>

          <p className={`${styles.subtitleRegular} text-site-gray mt-6`}>
            Now sign documents, agreements and more with the most advanced
            digital signature technology.
          </p>

          <p className={`${styles.subtitleRegular} text-site-gray mt-6`}>
            See the live demo by signing a PDF or integrate it in your website.
          </p>

          <div className={`${styles.flexStart} flex-wrap mt-7`}>
            <button
              onClick={() =>
                window.open(
                  "https://pdf.realsign.app",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              className={`min-h-[50px] outline-none ${styles.subtitleRegular} font-semibold rounded-3xl px-6 mr-2 my-2 text-white bg-site-green opacity-100 hover:opacity-80`}
            >
              Sign a PDF
            </button>
            <button
              onClick={() => window.open("https://docs.realsign.app")}
              className={`min-h-[50px] outline-none px-6 ${styles.subtitleBold} border-[1px] border-site-green rounded-3xl ml-2 my-2 text-site-green opacity-100 hover:opacity-80`}
            >
              Integrate &gt;
            </button>
          </div>
        </motion.div>

        <motion.div
          className={`flex-1 ${styles.flexCenter} flex-row w-full h-full relative lg:ml-10 ml-0 lg:mt-0 mt-10`}
          variants={slideLeftVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
        >
          <div className="w-36 h-36 rounded-full bg-site-orange absolute -top-10 -left-16 z-[-1]" />

          <img
            src={heroImg}
            alt="hero"
            className="w-[50%] h-full object-contain"
          />
          <img
            src={heroImg2}
            alt="hero"
            className="w-[50%] h-full object-contain"
          />

          <div className="w-56 h-56 rounded-full bg-site-blue absolute -bottom-5 -right-20 z-[-1]" />
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
