import React from "react";
import { motion } from "framer-motion";

import styles from "../styles";
import { done, doneGreen, price } from "../assets";
import { slideLeftVariants, slideRightVariants } from "../utils/motion";

const plans = [
  {
    type: "core",
    name: "PDF Sign",
    price: "$0.2",
    subtitle: "per sign",
    includes: [
      "Instant User Registration/Sign Up",
      "Inbuilt Secure Timestamping",
      "Optional KYC Enforcement",
      "PDF Signature Verification SDK",
      "Bulk PDF Signing (Coming soon)",
    ],
    tickImg: doneGreen,
    btnText: "Sign Up",
    bgColor: "bg-white",
    textColor: "text-site-black",
    paraColor: "text-site-gray",
    btnColor: "bg-site-green",
  },
  {
    type: "coming",
    name: "JSON Sign",
    price: "--",
    subtitle: "per sign",
    includes: [
      "Instant User Registration/Sign Up",
      "Inbuilt Secure Timestamping",
      "Optional KYC Enforcement",
      "JSON Signature Verification SDK",
      "Bulk JSON Signing (Coming soon)",
    ],
    tickImg: done,
    btnText: "Coming Soon",
    bgColor: "bg-white",
    textColor: "text-site-black",
    paraColor: "text-site-gray",
    btnColor: "bg-site-gray",
  },
];

const PriceCard = ({ plan, index }) => (
  <div
    className={`${
      styles.flexStart
    } flex-1 w-full flex-col p-6 rounded-[25px] shadow-lg ${plan.bgColor} ${
      index === 1 ? "md:ml-10 ml-0 md:mt-0 mt-10" : ""
    }`}
  >
    <div className="flex justify-between w-full">
      <h3 className={`${styles.heading3} ${plan.textColor}`}>{plan.name}</h3>

      <div className="flex justify-end text-right flex-col">
        <h3 className={`${styles.heading4} ${plan.textColor}`}>{plan.price}</h3>
        <p className={`${styles.bodyText} ${plan.paraColor} -mt-1`}>
          {plan.subtitle}
        </p>
      </div>
    </div>

    <div className={`w-[20%] h-[4px] ${plan.btnColor} rounded-md mt-3`} />

    <div className={`${styles.flexStart} mt-10 flex-col`}>
      <p className={`${styles.subtitleBold} ${plan.textColor}`}>
        Plan includes:
      </p>

      <div className="w-full mt-5">
        {plan.includes.map((include, index) => (
          <div
            className={`${styles.flexStart} flex-row mb-5`}
            key={`${plan.name}-${index}`}
          >
            <img
              src={plan.tickImg}
              alt="right"
              className={`w-5 h-5 object-contain mt-1`}
            />
            <p className={`${styles.bodyText} ${plan.paraColor} flex-1 ml-2`}>
              {include}
            </p>
          </div>
        ))}
      </div>
    </div>

    <div className="w-full mt-10">
      <button
        onClick={() => window.open("https://client.realsign.app/sign-up")}
        type="button"
        className={`w-full rounded-[25px] px-6 py-4 ${plan.btnColor} text-site-white font-poppins font-medium text-base`}
      >
        {plan.btnText}
      </button>
    </div>
  </div>
);

const Pricing = () => {
  return (
    <section
      className={`${styles.flexCenter} ${styles.paddings} bg-site-light min-h-screen overflow-x-hidden hide-scrollbar`}
    >
      <div
        className={`${styles.flexStart} ${styles.innerWidth} xl:flex-row flex-col`}
      >
        <span id="Pricing" className="hash-span" />

        <motion.div
          className={`${styles.flexStart} flex-1 md:p-12 p-6 bg-site-green rounded-[20px] flex-col`}
          variants={slideRightVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
        >
          <h3 className={`${styles.heading2} text-site-white sm:mb-10 mb-0`}>
            Save your <br /> Time & Money!
          </h3>

          <p className={`${styles.bodyText} text-site-light-white mt-6`}>
            RealSign is frictionless for adoption with OS Independent Web
            Integration, Instant User Registration/Sign Up, and Free of Cost
            Access for end user.
          </p>

          <p className={`${styles.bodyText} text-site-light-white mt-6`}>
            Forget the Business, Enterprise etc. plans offered traditionally by
            many PhotoSign solutions, with RealSign you only pay for what you
            use.
          </p>

          <div className="w-full mt-10">
            <img
              src={price}
              alt="pricing"
              className="w-full h-auto object-contain"
            />
          </div>
        </motion.div>

        {/* affordable pricing section */}

        <motion.div
          className={`${styles.flexStart} flex-[1.5] md:p-6 px-0 xl:ml-10 ml-0 xl:mt-0 mt-10 flex-col`}
          variants={slideLeftVariants}
          exit="exit"
          initial="hidden"
          whileInView="visible"
        >
          <h3 className={`${styles.heading2} text-left text-site-black`}>
            Our affordable <br /> pricing
          </h3>

          <p className={`${styles.bodyText} text-site-gray text-left mt-6`}>
            RealSign helps you go paperless and digitally secure in very
            affordable pay-as-you-go model.
          </p>

          <p className={`${styles.bodyText} text-site-gray text-left mt-6`}>
            You only pay for what you use:
          </p>

          <div
            className={`${styles.flexStart} md:flex-row flex-col w-full mt-10`}
          >
            {plans.map((plan, index) => (
              <PriceCard key={plan.name} plan={plan} index={index} />
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Pricing;
