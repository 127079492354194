import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "../styles";
import { logo } from "../assets";

const footerLinks = [
  {
    id: "footer-link-1",
    title: "Company",
    links: [
      {
        id: "footer-link-1-1",
        name: "About",
        link: "/about",
      },
      {
        id: "footer-link-1-2",
        name: "Privacy Policy",
        href: "https://realsign.app/privacy.html",
      },
      {
        id: "footer-link-1-3",
        name: "Terms and Conditions",
        href: "https://realsign.app/terms-and-conditions.html",
      },
    ],
  },
  {
    id: "footer-link-2",
    title: "Support",
    links: [
      {
        id: "footer-link-2-1",
        name: "FAQs",
        link: "/faqs",
      },
      {
        id: "footer-link-2-2",
        name: "PKI Audit",
        link: "/pki-audit",
      },
      {
        id: "footer-link-2-3",
        name: "Documentation",
        link: "/documentation",
      },
    ],
  },
  {
    id: "footer-link-3",
    title: "Contact",
    links: [
      {
        id: "footer-link-3-1",
        name: "support@realsign.app",
      },
      {
        id: "footer-link-3-2",
        name: "+917978605738",
      },
    ],
  },
];

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer
      className={`${styles.xPaddings} ${styles.topPadding} ${styles.flexCenter}`}
    >
      <div className={`${styles.innerWidth} flex flex-col w-full`}>
        <div className="flex xl:flex-row flex-col w-full">
          <div className="flex flex-col max-w-[400px]">
            <img src={logo} alt="real_sign" className="w-40 object-contain" />
            <p
              className={`${styles.subtitleRegular} text-site-black text-left mt-5`}
            >
              The fastest and simple way to sign your documents digitally safe
              and secure.
            </p>
          </div>

          <div className="flex-1 flex flex-row flex-wrap justify-between items-start xl:ml-10 ml-0 xl:mt-0 mt-10">
            {footerLinks.map((footerlink, index) => (
              <div
                key={footerlink.id}
                className={`flex flex-col min-w-[120px] ${
                  index !== footerLinks.length - 1 ? "mr-5 mb-5" : "ml-0"
                }`}
              >
                <h4
                  className={`${styles.subtitleBold} text-site-black text-left`}
                >
                  {footerlink.title}
                </h4>
                <ul className="list-none flex flex-col mt-5">
                  {footerlink.links.map((link) => (
                    <li
                      key={link.id}
                      className={`${styles.bodyText} text-site-gray hover:text-site-black text-left cursor-pointer mb-2`}
                      onClick={() => {
                        if (link.href) {
                          window.open(link.href);
                        } else if (link) {
                          window.scrollTo(0, 0);
                          navigate(link.link);
                        }
                      }}
                    >
                      {link.name}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div
          className={`${styles.flexCenter} py-6 mt-10 border-t border-site-light-green`}
        >
          <p className={`${styles.bodyText} text-center text-site-green`}>
            Copyright Ⓒ 2022 RealSign. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
